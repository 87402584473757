<template>
  <tr v-if="!!itemStatus">
    <td>
      <p class="pm-0 font-weight-bold">{{ itemStatus.title }}</p>
    </td>
    <td><i class="fa-1-5x far" :class="icon"></i></td>
    <td>
      <modal-btn :target="`#${modalId}`" v-if="!disabled" btn-class="link">
        <small class="font-weight-bold">تعديل</small>
      </modal-btn>
      <small class="danger-bold disabled" v-else>لا يمكن التعديل</small>
    </td>
    <teleport to="#app">
      <confirm-model :target-id="modalId" :title="itemStatus.title" @confirmed="changeStatus">
        <span>هل أنت متأكد من:</span>
        <p class="alert alert-info mt-1 mb-0">
          <span>{{ question }} ؟</span>
        </p>
      </confirm-model>
    </teleport>
  </tr>
</template>

<script>
import ModalBtn from "@/components/UI/ModalBtn";
import ConfirmModel from "@/components/UI/ConfirmModel";
import AuctionStatusMixin from "@/mixins/AuctionStatusMixin";

export default {
  components: {ConfirmModel, ModalBtn},
  mixins: [AuctionStatusMixin],
  emits: ['change-status'],
  props: {
    itemStatus: {required: true},
    status: {required: true},
    priceLess: {required: true},
    transferred: {required: true},
    invoice: {default: {}},
    result: {default: {}},
  },
  computed: {
    statusId() {
      return !!this.itemStatus ? this.itemStatus.id : ''
    },
    modalId() {
      return `change-status${this.statusId}`
    },
    checked() {
      return !!this.itemStatus && this.itemStatus.value
    },
    title() {
      return !!this.itemStatus ? this.itemStatus.title : ''
    },
    icon() {
      return !this.checked ? 'fa-times-circle text-danger' : 'fa-check-circle text-success'
    },
    question() {
      if (!this.title)
        return 'تعديل الحالة'
      return (this.checked ? 'إلغاء ' : '') + this.title
    },
    disabled() {
      switch (this.statusId) {
        case 'pay_price':
          return this.isStumbledState || this.hasInvoice || this.controlReceiving || this.isSold
        case 'pay_floor':
          return this.isStumbledState || this.isSold
        case 'stumbled':
          return this.priceLess || this.isPaid || this.isFloorPaid || this.isSold || this.controlReceiving
        case 'sold_by_com':
          return this.isPaid || this.isUncheckedInvoiceState || this.isReceivedState || (this.controlReceiving && !(this.isSold && !this.transferred))
        case 'received':
          return !this.controlReceiving
        case 'without_invoice':
          return (this.isUnpaidState) || (this.isStumbledState) || (this.hasInvoice) || (this.isReceivedState) || (this.isSold && !this.transferred)
        default:
          return false
      }
    },
  },
  methods: {
    changeStatus() {
      if (!!this.statusId)
        this.$emit('change-status', this.statusId)
      this.hideModal(`#${this.modalId}`);
    }
  }
}
</script>

<style scoped>
td {
  padding: 0.25rem 0;
}
</style>