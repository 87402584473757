const errors = {
  data() {
    return {};
  },
  methods: {
    checkDate(event) {
      let alertMessage = "";
      const now = new Date();
      // TO ENABLE START DATE FROM NOW
      now.setMinutes(now.getMinutes() - 1);
      let start_date = $_("#start_date");
      let finish_date = $_("#finish_date");
      if (!!event.target.attributes.flag) {
        const flag = event.target.attributes.flag.textContent;
        start_date = $_("#start_date" + flag);
        finish_date = $_("#finish_date" + flag);
      }

      if (
        !!start_date.val() &&
        !!finish_date.val() &&
        new Date(start_date.val()) > new Date(finish_date.val())
      ) {
        alertMessage = "تاريخ البدء اكبر من الإنتهاء";
      }
      if (!!finish_date.val() && now > new Date(finish_date.val())) {
        alertMessage = "تاريخ الإنتهاء قديم";
      }
      if (!!alertMessage) {
        switch (event.target.id) {
          case "start_date":
          case "start_date_restart":
            start_date.val("");
            break;
          case "finish_date":
          case "finish_date_restart":
            finish_date.val("");
            break;
        }
        http.popupMessage("error", `خطأ, ${alertMessage}`);
      }
      return false;
    },
  },
};

export default errors;
