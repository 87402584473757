<template>
  <tr :id="userRow" :class="{ pointer: radio }" @click="selectRadio">
    <td class="border" :class="border" v-if="radio">
      <input
        class="pointer"
        type="radio"
        name="user"
        :value="user.id"
        :id="`user${user.id}`"
      />
    </td>
    <td class="border" :class="border">{{ user.name }}</td>
    <td class="border" :class="border">{{ user.username }}</td>
    <td class="border" :class="border">
      <a class="link" :href="`tel:${user.mobile}`">{{ user.mobile }}</a>
    </td>
    <td class="border" :class="border" v-if="usersList">{{ user.city }}</td>
    <td class="border" :class="border" v-if="usersList">
      <label class="badge" :class="`badge-${userStatus.class}`">{{
        userStatus.text
      }}</label>
    </td>
    <td class="border" :class="border" v-if="usersList">
      {{ user.file_no || "--" }}
    </td>
    <td
      class="border"
      :class="border"
      v-if="withDate"
      v-html="formattedCreatedAt"
    ></td>
    <td class="border" :class="border" v-if="editing && canUpdate">
      <router-link
        class="btn btn-sm btn-primary rounded-0 user-buttons"
        :to="{
          name: usersList ? 'users.edit' : 'assistants.edit',
          params: { user: user.id },
        }"
      >
        تعديل
      </router-link>
      <modal-btn
        btn-class="btn-sm btn-soft-danger user-buttons"
        :target="`#${deleteId}`"
        >حذف</modal-btn
      >
    </td>
    <teleport to="#app" v-if="!!deleteUrl || canUpdate">
      <items-destroyer
        :url="deleteUrl"
        :row-id="userRow"
        :target-id="deleteId"
        @deleted="deleted"
        title="حذف مستخدم"
      >
        <span>هل أنت متأكد من حذف المستخدم</span>&nbsp;<span class="link">
          {{ user.name }}</span
        >
        ؟
      </items-destroyer>
    </teleport>
  </tr>
</template>

<script>
import ModalBtn from "@/components/UI/ModalBtn";
import ItemsDestroyer from "@/components/Common/ItemsDestroyer";
import Storage from "@/common/Storage";
import types from "@/common/types";
import date from "@/common/dateMethods";

export default {
  components: { ItemsDestroyer, ModalBtn },
  emits: ["deleted", "selected"],
  props: {
    user: { required: true },
    editing: { default: true },
    usersList: { default: false },
    radio: { default: false },
    border: { default: "border-light" },
    withDate: { type: Boolean, default: true },
  },
  computed: {
    canUpdate() {
      return this.$store.getters.canUpdateUser;
    },
    mobile() {
      return !!this.user.primary_mobile ? this.user.primary_mobile.mobile : "";
    },
    userStatus() {
      if (!this.user) return { text: "", class: "" };
      if (types.checkY(this.user.should_pay_auctions))
        return { text: "متعثر", class: "danger" };
      if (!this.user.mobile || !types.checkY(this.user.mobile_verified))
        return { text: "الهاتف غير مؤكد", class: "warning" };
      if (!this.user.insurance_id)
        return { text: "يحتاج تفعيل", class: "info" };
      return { text: "مفعل", class: "success" };
    },
    deleteUrl() {
      if (!this.user) return "";
      return `/users/${this.user.id}`;
    },
    deleteId() {
      if (!this.user) return "";
      Storage.removeStartWith("admin-counters");
      return `delete-user-${this.user.id}`;
    },
    userRow() {
      if (!this.user) return "";
      return `user-row-${this.user.id}`;
    },
    formattedCreatedAt() {
      if (!this.user || !this.user.created_at) return "";

      return date.withTime(this.user.created_at);
    },
  },
  methods: {
    deleted(id) {
      Storage.remove("admin-counters");
      this.$emit("deleted", id);
    },
    selectRadio() {
      if (!!this.user) {
        $_(`#user${this.user.id}`).prop("checked", true);
        this.$emit("selected", this.user.id);
      }
    },
  },
  created() {},
};
</script>

<style scoped>
.user-buttons {
  width: 60px;
  margin: 0 0.15rem;
}
</style>
