import types from "@/common/types";

export default {
  computed: {
    hasInvoice() {
      return !!this.invoice && !!this.invoice.id;
    },
    hasCheckedInvoice() {
      return !!this.invoice && types.checkY(this.invoice.checked);
    },
    isSold() {
      return this.findItem("sold_by_com");
    },
    isPaid() {
      return this.findItem("pay_price");
    },
    isFloorPaid() {
      return this.findItem("pay_floor");
    },
    isWithoutInvoice() {
      return this.findItem("without_invoice");
    },
    isStumbledState() {
      return !!this.status && parseInt(this.status.number) === 2;
    },
    isUnpaidState() {
      return !!this.status && parseInt(this.status.number) === 3;
    },
    isNeedInvoiceState() {
      return !!this.status && parseInt(this.status.number) === 4;
    },
    isUncheckedInvoiceState() {
      return !!this.status && parseInt(this.status.number) === 5;
    },
    isNotReceivedState() {
      return !!this.status && parseInt(this.status.number) === 6;
    },
    isReceivedState() {
      return !!this.status && parseInt(this.status.number) === 7;
    },
    controlReceiving() {
      return !!this.status && [6, 7].includes(parseInt(this.status.number));
    },
  },
  methods: {
    findItem(id) {
      try {
        return (
          !!this.result &&
          !!this.result.find((item) => {
            return item.id === id && item.value;
          })
        );
      } catch {
        return false;
      }
    },
  },
};
