<template>
  <div>
    <form @submit.prevent="assign" v-if="!emptyList">
      <div class="row">
        <div class="col-sm-12 d-flex justify-content-end">
          <button type="submit" class="btn btn-success nr-shadow mr-1">تعيين</button>
          <button type="button" class="btn btn-secondary nr-shadow" @click="hideUsers">إلغاء</button>
        </div>
        <input type="hidden" id="price" name="price" v-model="selectedPrice" />
        <input type="hidden" id="user" name="user" v-model="selectedUser" />
      </div>
    </form>
    <hr>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <h5>السعر</h5>
        </div>
        <div class="form-group" v-if="currentPrice">
          <input class="pointer mr-1" type="radio" id="select_price" name="select_price" @click="selectCurrentPrice" :value="currentPrice">
          <label class="pointer" for="select_price">
            <span>السعر الحالي</span>&nbsp;
            <strong>{{ currentPrice }}</strong>&nbsp;
            <span>دينار</span>
          </label>
        </div>
        <div class="form-group">
          <input class="pointer mr-1" type="radio" id="new_select_price" name="select_price" value="" @click="changeAuctionPrice(true)">
          <label class="pointer" for="new_select_price">تغيير السعر</label>
        </div>
        <div class="form-group">
          <input type="number" class="form-control" v-model="selectedPrice" placeholder="سعر المركبة النهائي" v-if="changePrice">
          <small class="w-100">
            <strong class="error hide" id="price_error"></strong>
          </small>
        </div>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-sm-12">
        <p>تحديد المستخدم</p>
        <users-filters @filters-changed="setFilters"></users-filters>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="table-responsive shadow">
          <table class="table table-bordered table-hover table-striped">
            <thead>
            <tr class="bg-bb">
              <th>#</th>
              <th>الإسم</th>
              <th>إسم المستخدم</th>
              <th>رقم الهاتف</th>
            </tr>
            </thead>
            <tbody>
            <UserRow
              v-for="user in list"
              :key="user.id"
              :user="user"
              :editing="false"
              :radio="true"
              :with-date="false"
              @selected="setUser"
            />
            </tbody>
          </table>
        </div>
        <small class="w-100">
          <strong class="error hide" id="user_id_error"></strong>
        </small>
      </div>
    </div>
    <div class="row pb-5">
      <div class="col-sm-12">
        <div class="bg-e0">
          <ThePaginator
            url="/users"
            :last-page="lastPage"
            :total="total"
            :links="links"
            @link-changed="setLink"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThePaginator from "@/components/UI/ThePaginator";
import pagination from "@/mixins/pagination";
import UsersFilters from "@/components/Users/UsersFilters";
import UserRow from "@/components/Users/UserRow";

export default {
  components: {UserRow, UsersFilters, ThePaginator},
  mixins: [pagination],
  emits: ['hide-users', 'assign'],
  props: {
    currentPrice: {required: true}
  },
  data() {
    return {
      selectedFilters: '',
      changePrice: false,
      selectedPrice: '',
      selectedUser: '',
    };
  },
  computed: {
    defaultUrl() {
      return '/users';
    },
  },
  methods: {
    assign(e) {
      return this.$emit('assign', e);
    },
    hideUsers() {
      return this.$emit('hide-users');
    },
    changeAuctionPrice(mode) {
      this.changePrice = mode;
    },
    setUser(userId) {
      this.selectedUser = userId
    },
    setPrice(price) {
      this.selectedPrice = price
    },
    selectCurrentPrice() {
      this.changePrice = false
      this.setPrice(this.currentPrice)
    }
  },
  async created() {
    await this.setList();
  }
}
</script>

<style scoped>
.table-bordered th,
td {
  border: 1px solid white;
}

table * {
  font-size: 0.9rem;
}
</style>
