<template>
  <section>
    <div>
      <button
        v-if="showResults"
        type="button"
        class="btn btn-sm rounded-0 btn-danger"
        title="إلغاء البحث"
        @click="resetFilters"
      >
        إلغاء البحث
      </button>
      <modal-btn
        v-else
        :target="`#${modalId}`"
        btn-class="btn-sm btn-info"
        title="بحث"
      >
        <span class="mr-1">بحث</span>
        <i class="fa fa-search"></i>
      </modal-btn>
      <div v-if="showResults">
        <label class="d-block pm-0">نتائج البحث: </label>
        <small v-if="name" class="space-x-1">
          <strong>الإسم: </strong>
          <span>{{ name }},</span>
        </small>
        <small v-if="username" class="space-x-1">
          <strong>إسم المستخدم: </strong>
          <span>{{ username }},</span>
        </small>
        <small v-if="city_id" class="space-x-1">
          <strong>المحافظة: </strong>
          <span>{{ cityText }},</span>
        </small>
        <small v-if="mobile" class="space-x-1">
          <strong>رقم الهاتف: </strong>
          <span>{{ mobile }},</span>
        </small>
        <small v-if="national_number" class="space-x-1">
          <strong>الرقم الوطني: </strong>
          <span>{{ national_number }},</span>
        </small>
        <small v-if="status" class="space-x-1">
          <strong>حالة الحساب: </strong>
          <span>{{ userStatusText }}</span>
        </small>
        <small v-if="file_no" class="space-x-1">
          <strong>رقم الملف: </strong>
          <span>{{ file_no }}</span>
        </small>
        <small v-if="date_from" class="space-x-1">
          <strong>التاريخ من: </strong>
          <span>{{ date_from }}</span>
        </small>
        <small v-if="date_to" class="space-x-1">
          <strong>التاريخ إلى: </strong>
          <span>{{ date_to }}</span>
        </small>
      </div>
    </div>
    <teleport to="#app">
      <confirm-model
        :modal-lg="true"
        :target-id="modalId"
        title="بحث المستخدمين"
        confirm-text="بحث"
        cancel-btn="hide"
        @confirmed="setFilters"
        ref="users_filter"
      >
        <div class="container" id="user-filters">
          <div class="row">
            <div class="col-sm-6">
              <small class="link"><abbr :title="partSearch">الإسم</abbr></small>
              <FormInput
                input-class="filter-inputs"
                placeholder="الإسم"
                id="name"
              />
            </div>
            <div class="col-sm-6" v-if="!assistantsList">
              <small class="link"
                ><abbr :title="menuSearch">المحافظة</abbr></small
              >
              <BootstrapSelect
                title="المحافظة"
                select-class="filter-select filter-inputs"
                id="city_id"
                :options="cities"
              />
            </div>
            <div class="col-sm-6">
              <small class="link"
                ><abbr :title="partSearch">إسم المستخدم</abbr></small
              >
              <FormInput
                input-class="filter-inputs"
                placeholder="إسم المستخدم"
                id="username"
              />
            </div>
            <div class="col-sm-6">
              <small class="link"
                ><abbr :title="partSearch">رقم الهاتف</abbr></small
              >
              <FormInput
                input-class="filter-inputs"
                id="mobile"
                placeholder="رقم الهاتف"
              />
            </div>
            <div class="col-sm-6">
              <small class="link"
                ><abbr :title="partSearch">الرقم الوطني</abbr></small
              >
              <FormInput
                input-class="filter-inputs"
                placeholder="الرقم الوطني"
                id="national_number"
              />
            </div>
            <div class="col-sm-6" v-if="!assistantsList">
              <small class="link"
                ><abbr :title="menuSearch">حالة الحساب</abbr></small
              >
              <BootstrapSelect
                title="حالة الحساب"
                select-class="filter-select filter-inputs"
                id="status"
                :options="userStatus"
              />
            </div>
            <div class="col-sm-6">
              <small class="link">التاريخ من</small>
              <FormInput
                type="date"
                input-class="filter-inputs"
                placeholder="التاريخ من"
                id="date_from"
              />
            </div>
            <div class="col-sm-6">
              <small class="link">التاريخ إلى</small>
              <FormInput
                type="date"
                input-class="filter-inputs"
                placeholder="التاريخ إلى"
                id="date_to"
              />
            </div>
          </div>
          <div class="row" v-if="!assistantsList">
            <div class="col-sm-4 p-1">
              <small class="link"
                ><abbr :title="partSearch">رقم الملف</abbr></small
              >
              <FormInput
                type="number"
                input-class="filter-inputs"
                placeholder="رقم الملف"
                id="file_no"
              />
            </div>
            <div class="col-sm-4 p-1">
              <small class="link"
                ><abbr :title="partSearch">حرف المجلد</abbr></small
              >
              <FormInput
                dir="rtl"
                input-class="filter-inputs"
                placeholder="حرف المجلد"
                id="folder_letter"
              />
            </div>
            <div class="col-sm-4 p-1">
              <small class="link"
                ><abbr :title="partSearch">رقم المجلد</abbr></small
              >
              <FormInput
                type="number"
                input-class="filter-inputs"
                placeholder="رقم المجلد"
                id="folder_no"
              />
            </div>
          </div>
        </div>
      </confirm-model>
    </teleport>
  </section>
</template>

<script>
import ModalBtn from "@/components/UI/ModalBtn";
import ConfirmModel from "@/components/UI/ConfirmModel";
import FormInput from "@/components/Forms/FormInput";
import BootstrapSelect from "@/components/Forms/BootstrapSelect";

export default {
  components: { BootstrapSelect, FormInput, ConfirmModel, ModalBtn },
  emits: ["filters-changed"],
  data() {
    return {
      name: "",
      city_id: "",
      username: "",
      mobile: "",
      national_number: "",
      status: "",
      file_no: "",
      date_from: "",
      date_to: "",
    };
  },
  computed: {
    assistantsList() {
      return this.$route.name === "assistants.list";
    },
    cities() {
      return this.$store.getters.cities;
    },
    userStatus() {
      // value = 3 is for user needs to update his data (account priorities)
      return [
        { id: 1, content: "محجوز" },
        { id: 2, content: "الهاتف غير مؤكد" },
        { id: 4, content: "يحتاج تفعيل" },
        { id: 5, content: "مفعل" },
      ];
    },
    modalId() {
      return "users-filter";
    },
    showResults() {
      return (
        this.name ||
        this.city_id ||
        this.username ||
        this.mobile ||
        this.national_number ||
        this.status ||
        this.file_no ||
        this.date_from ||
        this.date_to
      );
    },
    cityText() {
      if (!this.city_id || !this.cities) {
        return "";
      }

      const city = this.cities.find((city) => {
        return parseInt(city.id) === parseInt(this.city_id);
      });
      return !!city ? city.content : "";
    },
    userStatusText() {
      if (!this.status || !this.userStatus) {
        return "";
      }

      const status = this.userStatus.find((status) => {
        return parseInt(status.id) === parseInt(this.status);
      });
      return !!status ? status.content : "";
    },
    menuSearch() {
      return "يتم البحث من خلال إختيار قيمة من القائمة";
    },
    partSearch() {
      return "لا يشترط كتابة الكلمة كاملة بإمكانك كتابة جزء منها للبحث";
    },
  },
  methods: {
    setFilters() {
      this.name = $_("#name").val();
      this.city_id = $_("#city_id").val();
      this.username = $_("#username").val();
      this.mobile = $_("#mobile").val();
      this.national_number = $_("#national_number").val();
      this.status = $_("#status").val();
      this.date_from = $_("#date_from").val();
      this.date_to = $_("#date_to").val();

      const file_no = $_("#file_no").val();
      const folder_letter = $_("#folder_letter").val();
      const folder_no = $_("#folder_no").val();

      if (!!file_no) {
        this.file_no = file_no;
      }

      if (!!folder_letter || !!folder_no) {
        if (!!this.file_no) {
          this.file_no = `${this.file_no}/${folder_letter + folder_no}`;
        } else {
          this.file_no = `${folder_letter + folder_no}`;
        }
      }

      if (!this.showResults) {
        return;
      }

      this.$emit("filters-changed", {
        name: this.name,
        city_id: this.city_id,
        username: this.username,
        mobile: this.mobile,
        national_number: this.national_number,
        status: this.status,
        file_no: this.file_no,
        date_from: this.date_from,
        date_to: this.date_to,
      });

      $_(".filter-inputs").val("");
      $_(".filter-select").selectpicker("val", "");
      this.hideModal(`#${this.modalId}`);
    },
    resetFilters() {
      this.name = "";
      this.city_id = "";
      this.username = "";
      this.mobile = "";
      this.national_number = "";
      this.status = "";
      this.file_no = "";
      this.date_from = "";
      this.date_to = "";
      this.$emit("filters-changed");
    },
    clickEnter() {
      $_(document).on("keypress", ".filter-inputs", (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          if (this.$refs.users_filter) {
            this.$refs.users_filter.confirm();
          }
        }
      });
    },
  },
  async created() {
    await this.$store.dispatch("setCitiesAction");
    await this.clickEnter();
  },
};
</script>

<style scoped>
.fa {
  height: 14px;
  width: 14px;
}

.col-sm-6 {
  padding: 0.25rem;
}
</style>
